import React, { useEffect, useState } from 'react';
import { Text, Combobox, InputBase, useCombobox, Flex } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import ConditionalComponent from 'components/ConditionalComponent';
import useRailway from './hook/useRailway';
import useAvia from './hook/useAvia';
import classes from './AviaRailwayForm.module.css';

type Props = React.PropsWithChildren<{
  name: string;
  label?: string;
  placeholder?: string;
  width?: string;

  onSelect?: (region: any) => void;
  type?: 'avia' | 'railway';

  value?: any;
  defaultName?: string;
  required?: boolean;
  clearAfterSelect?: boolean;
}>;

function AviaRailwayForm({
  name,
  onSelect,
  placeholder,
  value,
  type = 'avia',
  defaultName = '',
  label,
  clearAfterSelect,
  required,
  width
}: Props) {
  const { t } = useTranslation();
  const { control, formState: { errors } } = useFormContext();

  const [inputValue, setInputValue] = useState(() => value?.name || defaultName);
  const [debouncedInputValue] = useDebouncedValue(inputValue, 600);

  const getError = (path: string, errors: any) => {
    return path.split('.').reduce((acc: any, curr) => acc && acc[curr], errors);
  };

  const error = getError(name, errors);
  const errorMessage = error?.message || (error?.name?.message);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const [objects, { fetch }] = type === 'avia' ? useAvia() : useRailway();

  const names = objects.map((h) => ({
    label: h.name || h.city,
    value: h.code,
    description: `${h.city || ''}, ${h.country || ''}`.trim()
  }));

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 2) {
      fetch(debouncedInputValue);
    }
  }, [fetch, debouncedInputValue]);

  useEffect(() => {
    if (defaultName) {
      fetch(defaultName);
      setInputValue(defaultName);
    }
  }, [defaultName]);

  useEffect(() => {
    if (value?.name) {
      setInputValue(value.name);
    }
  }, [value]);

  const handleSelect = (object: string) => {
    // @ts-ignore */
    const data = objects?.find((r) => r.code === object || r.place_id === object);

    if (data) {
      onSelect && onSelect(data);
    }
    if (clearAfterSelect) setTimeout(handleClear, 0);
  };

  const handleClear = () => {
    setInputValue('');
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Combobox
          store={combobox}
          onOptionSubmit={(val) => {
            const selectedItem = names.find((item) => item.value === val);
            onChange(val);
            setInputValue(selectedItem?.label || '');
            handleSelect(val);
            combobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <InputBase
              w={width}
              label={label}
              error={errorMessage}
              errorProps={{ className: classes.errorLabel }}
              rightSection={<Combobox.Chevron />}
              value={inputValue}
              required={required}
              onChange={(event) => {
                const newValue = event.currentTarget.value;
                setInputValue(newValue);
                combobox.openDropdown();
                combobox.updateSelectedOptionIndex();
              }}
              placeholder={placeholder || t('Objects.Airport.Form.Placeholder')}
              rightSectionPointerEvents='none'
            />
          </Combobox.Target>

          <Combobox.Dropdown className={classes.options}>
            <Combobox.Options>
              <ConditionalComponent
                condition={names.length > 0}
                fallback={<Combobox.Empty>Nothing found</Combobox.Empty>}
              >
                <>
                  {names.map((item) => (
                    <Combobox.Option value={item.value} key={item.value}>
                      <Flex direction='column' gap='4px'>
                        <Text size='sm'>{item.label}</Text>
                        <Text size='xs' c='dimmed'>
                          {item.description}
                        </Text>
                      </Flex>
                    </Combobox.Option>
                  ))}
                </>
              </ConditionalComponent>
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      )}
    />
  );
}

export default AviaRailwayForm;
