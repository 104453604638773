import connection from 'connection/instance';
import { AxiosResponse } from 'axios';
import { IObjectResponse, IArticlesMeta, IObjectResponseData, IObjectUserMeta } from 'types';
import {
  IObjectDataFull,
  IObjectRequest,
  IObjectResponseDataOne,
  IObjectsFetchParams,
  IObjectsMeta, IDocument
} from 'types/object';

const TYPESENSE_API_KEY = process.env.REACT_APP_TYPESENSE_API_KEY;

export interface IObjectsParams {
  id: string;
  object: IObjectDataFull;
}

export interface IObjectParams {
  id: string;
  lang: string;
}

const BASE_URL = '/api/hotels';

const handleRequest = async <T>(
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  config: any = {}
): Promise<T> => {
  const response: AxiosResponse<T> = await connection[method](url, config);
  return response.data;
};

export const fetchAvia = async (q: string) => {
  const params = {
    q: q.toLowerCase(),
    query_by: 'index_strings',
    sort_by: '_text_match:desc',
    num_typos: 1
  };

  const headers = { 'X-TYPESENSE-API-KEY': TYPESENSE_API_KEY };

  const response = await handleRequest<{
    hits: Array<{
      document: IDocument;
    }>;
    error?: any;
  }>('get', '/api/ts/collections/airports/documents/search', { params, headers });

  return { data: response?.hits || [], error: response.error };
};

export const fetchRailway = async (q:string) => {
  const params = {
    q: q.toLowerCase(),
    query_by: 'index_strings',
    sort_by: '_text_match:desc',
    num_typos: 1
  };

  const headers = { 'X-TYPESENSE-API-KEY': TYPESENSE_API_KEY };

  const response = await handleRequest<{
    hits: Array<{
      document: IDocument;
    }>;
    error?: any;
  }>('get', '/api/ts/collections/stations/documents/search', { params, headers });

  return { data: response?.hits || [], error: response.error };
};

export const objectsFetch = async (page: number): Promise<IObjectResponse[]> => {
  const params: IObjectsFetchParams = {
    data: {
      paginate: {
        limit: 100,
        page
      }
    }
  };
  const response = await handleRequest<IObjectResponseData<IObjectResponse[], IObjectsMeta>>('get', BASE_URL, {
    params
  });
  return response.data;
};

export const objectsFetchOne = async (data: IObjectParams): Promise<any> => {
  const response = await handleRequest<IObjectResponseDataOne<any, IObjectsMeta>>('get', `${BASE_URL}/${data.id}`, {
    headers: { 'x-site-locale': data.lang }
  });
  return response.hotel;
};

export const objectsCreate = async (
  article: IObjectRequest
): Promise<IObjectResponseData<IObjectResponse, IObjectUserMeta>> => {
  return handleRequest<IObjectResponseData<IObjectResponse, IObjectUserMeta>>('post', BASE_URL, { data: article });
};

export const objectsUpdate = async (data: IObjectsParams): Promise<IObjectResponse> => {
  const response = await handleRequest<IObjectResponseDataOne<IObjectResponse, IArticlesMeta>>(
    'put',
    `${BASE_URL}/${data.id}`,
    { data: data.object }
  );
  return response.hotel;
};

export const objectsMigrate = async (data: any): Promise<any> => {
  return handleRequest<any>('put', `${BASE_URL}/${data.id}/migrate`, { data: data.object });
};

export const objectsDelete = async (id: string): Promise<IObjectResponse> => {
  const response = await handleRequest<IObjectResponseData<IObjectResponse, IArticlesMeta>>(
    'delete',
    `${BASE_URL}/${id}`
  );
  return response.data;
};

export const objectsPublish = async (id: string): Promise<IObjectResponse> => {
  const response = await handleRequest<IObjectResponseData<IObjectResponse, IArticlesMeta>>(
    'put',
    `${BASE_URL}/${id}/publish`
  );
  return response.data;
};

export const objectsUnpublish = async (id: string): Promise<IObjectResponse> => {
  const response = await handleRequest<IObjectResponseData<IObjectResponse, IArticlesMeta>>(
    'put',
    `${BASE_URL}/${id}/unpublish`
  );
  return response.data;
};
