import { useState, useCallback } from 'react';
import { fetchAvia } from 'apis/ObjectsApi';
import { IDocument } from 'types/object';

type useAvia = {
  (): [
    IDocument[],
    {
      fetch: (q: string) => void;
      clear: () => void;
    }
  ];
};

const useAvia: useAvia = () => {
  const [objects, setObjects] = useState<IDocument[]>([]);

  const fetch = useCallback((q: string) => {
    fetchAvia(q).then((response) => {
      if (response?.data) {
        setObjects(response.data.map((item) => item.document));
      } else {
        setObjects([]);
      }
    });
  }, []);

  const clear = () => {
    setObjects([]);
  };

  return [
    objects,
    {
      fetch,
      clear
    }
  ];
};

export default useAvia;
