import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IconXboxXFilled } from '@tabler/icons-react';
import { Box, Flex, Text, CloseButton } from '@mantine/core';

import AviaRailwayForm from 'components/ui/AviaRailwaySelectForm';
import { SANATORY_FIELDS } from '../schema';
import PrioritySelect from 'components/forms/PrioritySelect';
import { AirportAndRailwayField } from 'types/object';

type FormValues = {
  [SANATORY_FIELDS.AIRPORTS]: AirportAndRailwayField[];
};

interface AviaProps {
  initialAirports?: AirportAndRailwayField[];
}

function Avia({ initialAirports }: AviaProps) {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<FormValues>();
  const { fields, append, update, remove } = useFieldArray({
    name: SANATORY_FIELDS.AIRPORTS,
    control
  });

  useEffect(() => {
    if (!initialAirports) return;

    setValue(
      SANATORY_FIELDS.AIRPORTS,
      initialAirports.length
        ? initialAirports.map((airport) => ({
            ...airport,
            priority: airport.priority?.toString() || '',
          }))
        : [{}]
    );
  }, [initialAirports, setValue]);

  const handleSelect = (index: number, airportData: any) => {
    const currentPriority = fields[index]?.priority;

    const updatedField = {
      code: airportData.code,
      name: airportData.name,
      city: airportData.city,
      country: airportData.country,
      coordinates: airportData.coordinates,
      priority: currentPriority
    };

    update(index, updatedField);

    // @ts-ignore */
    setValue(`${SANATORY_FIELDS.AIRPORTS}.${index}`, updatedField, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };

  return (
    <Flex direction='column' mt='lg' gap='12px'>
      {fields.map((field, index) => {
        return (
          <Flex gap='16px' key={field.id} align='start'>
            <AviaRailwayForm
              width='100%'
              label={t('Objects.Airport.Form')}
              name={`${SANATORY_FIELDS.AIRPORTS}.${index}`}
              required
              onSelect={(data) => handleSelect(index, data)}
              value={field}
            />
            <PrioritySelect
              label={t('Objects.Priority.Form')}
              placeholder={t('Objects.Priority.Form.Placeholder')}
              name={`${SANATORY_FIELDS.AIRPORTS}.${index}.${SANATORY_FIELDS.PRIORITY}`}
              defaultValue={field.priority}
              required
            />
            {index === 0 && <Box w={32} h={32} ml='19px' mt='20px' />}
            {index !== 0 && fields.length > 1 && (
              <CloseButton
                onClick={() => remove(index)}
                mt='25px'
                variant='subtle'
                radius='xl'
                size={32}
                icon={<IconXboxXFilled color='#C4C4C4' size={32} />}
              />
            )}
          </Flex>
        );
      })}
      <Text c='#1F6FCC' fz='14px' fw='bold' style={{ cursor: 'pointer' }} onClick={() => append({})}>
        {t('Objects.Airport.Add')}
      </Text>
    </Flex>
  );
}

export default Avia;
