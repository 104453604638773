import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectForm from 'components/ui/SelectForm';

type Props = {
  label: string;
  placeholder?: string;
  defaultValue?: string | number;
  name: string;
  required?: boolean;
};

const PrioritySelect = ({ label, placeholder, name, required, defaultValue }: Props) => {
  const { t } = useTranslation();

  const types = [
    { value: '1', name: t('Objects.Priority.Form.1') },
    { value: '2', name: t('Objects.Priority.Form.2') },
    { value: '3', name: t('Objects.Priority.Form.3') }
  ];

  const priorityOptions = types.map((option) => ({
    value: option.value || defaultValue,
    label: option.name
  }));

  return (
    <SelectForm
      defaultValue={defaultValue}
      required={required}
      width='100%'
      name={name}
      label={label}
      options={priorityOptions as []}
      placeholder={placeholder}
    />
  );
};

export default PrioritySelect;
