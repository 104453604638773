export default {
  translation: {
    'Comments.Title': 'Лучший комментарий',
    'Comments.Location': 'Расположение',
    'Comments.Purity': 'Чистота',
    'Comments.Service': 'Обслуживание',
    'Comments.Price': 'Цена/качество',
    'Comments.Rating': 'Оценка',
    'Comments.Placeholder.Name': 'Введите имя комментатора',
    'Comments.Placeholder.Comment': 'Введите комментарий',
    'Comments.Error.Name': 'Лучший комментарий. Имя комментатора не может быть пустым',
    'Comments.Error.Title': 'Лучший комментарий. Заголовок не может быть пустым',
    'Comments.Error.Comment': 'Лучший комментарий. Комментарий не может быть пустым',
    'Comments.Placeholder.Title': 'Введите заголовок',

    'Available.Title': 'Календарь доступности',

    'Disease.Form.FieldsetTitle': 'Название заболевания',
    'Disease.Form.Label': 'Введите название',
    'Disease.Form.Placeholder': 'Введите название',

    'Description.Title': 'Описание санатория',
    'Description.About': 'Введите описание',
    'Description.AboutEN': 'Описание (EN)',
    'Description.Title.ChatGPT': 'Описание (ChatGPT)',
    'Description.Prompt': 'Введите запрос и нажмите кнопку "Сгенерировать описание"',
    'Description.Error.About': 'Описание. Описание не может быть пустым',
    'Description.Error.Prompt': 'Запрос. Запрос не может быть пустым',

    'Distances.Title': 'Как добраться',
    'Distances.Description': 'Введите описание',
    'Distances.Name': 'Введите название',
    'Distances.Error.Name': 'Питание. Заголовок блока({number}) не может быть пустым',
    'Distances.Error.Description': 'Питание. Описание блока({index}) не может быть пустым',
    'Distances.Unit.Km': 'Киллометры',
    'Distances.Unit.M': 'Метры',
    'Distances.Transport.Train': 'Поезд',
    'Distances.Transport.Plane': 'Самолет',
    'Distances.Transport.Transfer': 'Такси',
    'Distances.Placeholder.Unit': 'Введите расстояние',
    'Distances.Label.Unit': 'Выберите единицу измерения',
    'Distances.Label.Transport': 'Выберите транспорт',
    'Distances.Placeholder.Transport': 'Выберите транспорт',
    'Distances.Label.Value': 'Введите расстояние',
    'Distances.Placeholder.Value': 'Введите расстояние',

    'NewYear.Title': 'О программе',

    'Food.Title': 'Питание',
    'Food.Data.Description': 'Введите описание',
    'Food.Data.Title': 'Введите заголовок',
    'Food.Error.Title': 'Питание. Заголовок блока({number}) не может быть пустым',
    'Food.Error.Description': 'Питание. Описание блока({number}) не может быть пустым',

    'Documents.Title': 'Документы для поездки',
    'Documents.Data.Description': 'Введите описание',
    'Documents.Data.Title': 'Введите заголовок',
    'Documents.Error.Title': 'Документы для поездки. Заголовок блока({number}) не может быть пустым',
    'Documents.Error.Description': 'Документы для поездки. Описание блока({number}) не может быть пустым',

    'Procedures.Title': 'Процедуры',
    'Procedures.Data.Title': 'Процедура',
    'Procedures.Data.Includes': 'Введите список процедур',
    'Procedures.Error.Title': 'Процедуры. Заголовок блока({number}) не может быть пустым',
    'Procedures.Error.Includes': 'Процедуры. Список процедур блока({number}) не может быть пустым',
    'Procedures.Actions.Rewrite': 'Перезаписать процедуры',
    'Procedures.Actions.Complement': 'Дополнить процедуры',
    'Procedures.Actions.ApplyTemplate': 'Применить шаблон',
    'Procedures.Actions.Create': 'Создать процедуры',
    'Procedures.Placeholder.BlockInput': 'Вставьте данные из гугл таблицы',

    'Programs.Title': 'Программы',
    'Programs.Data.Description': 'Введите описание',
    'Programs.Data.List.Title': 'Программа',
    'Programs.Data.List.Description': 'Описание',
    'Programs.Data.List.Duration': 'Введите период',
    'Programs.Data.List.Includes': 'Введите список процедур',
    'Programs.Errors.Description': 'Программы. Описание не может быть пустым',
    'Programs.Errors.List.Title': 'Программы. Заголовок блока({number}) не может быть пустым',
    'Programs.Errors.List.Description': 'Программы. Описание блока({number}) не может быть пустым',
    'Programs.Errors.List.Duration': 'Программы. Длительности программы блока({number}) не может быть пустой',
    'Programs.List.Includes.Title': 'Что входит в программу',

    'Stars.Title': 'Выберите звездность',

    'RegionCard.Check': 'Посмотрите',
    'Blogcard.CommonText': 'Санаторий входит в ',

    'Action.Turbo': 'Яндекс Турбо',
    'Action.Save': 'Сохранить',
    'Action.Preview': 'Предпросмотр',
    'Action.Delete': 'Удалить',
    'Action.Cancel': 'Отмена',
    'Action.Edit': 'Редактировать',
    'Action.EditForm': 'Редактировать форму',
    'Action.ShowSite': 'Посмотреть на сайте',
    'Action.ToList': 'К списку',
    'Action.AddObject': 'Добавить объект',
    'Action.Add': 'Добавить',
    'Action.Understand': 'Понятно',
    'Action.ChangeProvider': 'Изменить провайдера',
    'SearchArea.RegionSelect.Placeholder': 'Введите регион',
    'SearchArea.Objects.Placeholder': 'Введите объект размещения',
    'SearchArea.Title': 'Области поиска',
    'SearchArea.Table.Field1': 'Название',
    'SearchArea.Table.Field2': 'Страна',
    'SearchArea.Table.Field3': 'Тип поиска',
    'SearchArea.Table.Field4': 'Доступен для поиска',
    'SearchArea.Table.Field5': 'Координаты',
    'SearchArea.Table.Field6': 'Радиус, км',
    'SearchArea.Catalog.Title': 'Страница каталога',

    'Objects.Table.Field1': 'Название',
    'Objects.Table.Field2': 'Страна',
    'Objects.Table.Field3': 'Дата создания',
    'Objects.Table.Field4': 'Дата обновления',
    'Objects.Table.Field5': 'Провайдер',

    'Objects.New.Title': 'Объекты размещения / Новый объект',
    'Objects.New.Subtitle': 'Новый объект',
    'Objects.Tab.Name1': 'Контент',
    'Objects.Tab.Name2': 'Данные объекта',

    'Objects.New.Form1.Title': 'Информация',
    'Objects.New.Form2.Title': 'Договоры',
    'Objects.New.Form3.Title': 'Ссылки',
    'Objects.New.Form.All': 'Общее',
    'Objects.New.Form.Name': 'Название',
    'Objects.New.Form.Name.Placeholder': 'Введите название',
    'Objects.New.Form.Address': 'Адрес',
    'Objects.New.Form.Address.Placeholder': 'Введите адрес',
    'Objects.New.Form.Email': 'Email',
    'Objects.New.Form.Email.Placeholder': 'Введите email',
    'Objects.New.Form.Phone': 'Телефон',
    'Objects.New.Form.Phone.Placeholder': 'Введите телефон',
    'Objects.New.Form.Law': 'Юридические данные',
    'Objects.New.Form.Inn': 'Инн',
    'Objects.New.Form.Kpp': 'Кпп',
    'Objects.New.Form.Currency': 'Валюта',
    'Objects.New.Form.Inn.Placeholder': 'Введите инн',
    'Objects.New.Form.Kpp.Placeholder': 'Введите кпп',
    'Objects.New.Form.Search': 'Поиск',
    'Objects.New.Form.SearchArea': 'Область поиска',
    'Objects.New.Form.Lng': 'Долгота',
    'Objects.New.Form.Lng.Placeholder': 'Введите долготу',
    'Objects.New.Form.Lat': 'Широта',
    'Objects.New.Form.Lat.Placeholder': 'Введите широту',
    'Objects.New.Form.Timezone': 'Часовой пояс',
    'Objects.New.Form.Special': 'Акции и дополнительные возможности',
    'Objects.New.Form2.DiscountSize': 'Размер скидки, %',
    'Objects.New.Form2.DiscountSize.Placeholder': '1-100',
    'Objects.New.Form2.Number': 'Номер',
    'Objects.New.Form2.Number.Placeholder': 'Введите номер',
    'Objects.New.Form2.Date': 'Дата',
    'Objects.New.Form2.Date.Placeholder': 'Введите дату',
    'Objects.New.Form2.Commission': 'Комиссия',
    'Objects.New.Form2.Commission.Placeholder': 'Введите коммиссию',
    'Objects.New.Form2.InstantBooking': 'Мгновенное подтверждение',
    'Objects.New.Form2.Cashback': 'Туристический кешбэк',

    'Objects.New.Form3.TripAdvisor': 'TripAdvisor',
    'Objects.New.Form3.TripAdvisor.Placeholder': 'Ссылка',
    'Objects.New.Form3.YoutubeLinks': 'YouTube (перечислите ссылки через запятую)',
    'Objects.New.Form3.YoutubeLinks.Placeholder': 'ссылка, ссылка, ссылка',

    'Objects.Modal.Title': 'Объект добавлен',
    'Objects.Modal.Login': 'Логин:',
    'Objects.Modal.Pass': 'Пароль:',

    'Objects.Tabs.Sanatory': 'Санаторий',
    'Objects.Tabs.Ostrovok': 'Островок',

    'Objects.Form.Ostrovok.ProviderId': 'Идентификатор объекта из островка',
    'Objects.Form.Ostrovok.ProviderId.Placeholder': 'Введите идентификатор объекта из островка',

    'Objects.Provider.Ostrovok.Title': 'Новый провайдер:',

    'Objects.AirportRailwayForm.Title': 'Ближайший аэропорт и ЖД вокзал',
    'Objects.AirportRailwayForm.Description': 'Укажите хотя бы один аэропорт и ЖД вокзал',
    'Objects.Airport.Form': 'Аэропорт',
    'Objects.Airport.Form.Placeholder': 'Введите название',
    'Objects.Railway.Form': 'ЖД возкзал',
    'Objects.Railway.Form.Placeholder': 'Введите название',
    'Objects.Airport.Add': 'Добавить аэропорт',
    'Objects.Railway.Add': 'Добавить ЖД вокзал',
    'Objects.Priority.Form': 'Приоритет',
    'Objects.Priority.Form.Placeholder': 'Выберите из списка',
    'Objects.Priority.Form.1': '1 – приоритетный объект',
    'Objects.Priority.Form.2': '2 – по удаленности',
    'Objects.Priority.Form.3': '3 – по удаленности',

    'Tariffs.Table.Field1': 'Название',
    'Tariffs.Table.Field2': 'Описание',
    'Tariffs.Table.Field3': 'Источник',
    'Tariffs.Table.Field4': 'Интервал',
    'Tariffs.Title': 'Тарифы',

    'Tariffs.Base': 'базовый',
    'Tariffs.Tour': 'смена',
    'Tariffs.Package': 'программа лечения',

    'Tariffs.Status.Label': 'Статус',
    'Tariffs.Status.New': 'новый',

    'Tariffs.Type.Label': 'Тип тарифного плана',

    'Tariffs.Rules.Content': 'При отмене в срок до {day} до даты заезда удерживается сумма в размере {penalty}',
    'Tariffs.Rules.Days': 'стоимости {count, plural, one {# дня} few {# дней} many {# дней}} стоимости тарифа',
    'Tariffs.Rules.Percent': '{count}% от суммы проживания',
    'Tariffs.Rules.Rub': '{count} руб.',
    'Tariffs.Rates.Title': 'Категории размещения',
    'Tariffs.Periods.Title': 'Периоды ценообразования',
    'Tariffs.Rates.Description': `{ageGroup, select,
      child {Ребенок}
      adult {Взрослый}
    } {occupation, select,
      main {на основном месте {count, plural,
        =0 {{ageGroup, select,
          child {}
          adult {с подселением}
        }}
        one {при 1-но местном размещении}
        few {при {count}-х местном размещении}
        many {при {count}-и местном размещении}
      }}
      extra {на дополнительном месте}
      no {без места}
    }
    {ageGroup, select,
      adult {}
      child {({from} - {to} лет)}
    }`,
    'Tariffs.Cancellation.FullRefund': 'Бесплатная отмена',
    'Tariffs.Cancellation.FullRefun.Short': 'Бесплатная отмена',
    'Tariffs.Cancellation.Paid.Short': 'Платная отмена',
    'Tariffs.Cancellation.FullTitle': 'Условия отмены',
    'Tariffs.Cancellation.NonRefund': 'При отмене удерживается полная сумма бронирования',
    'Tariffs.Cancellation.Label': 'Правила отмены бронирования',
    'Tariffs.RoomTypes.Title': 'Тип номера в Экстранет',
    'Tariffs.RoomTypes.TL.Title': 'Тип номера в TL',
    'Tariffs.RoomTypes.Placeholder': 'Тип комнаты',

    'Tariffs.AvailablePeriod.Label': 'Период действия',

    'Parsing.Add.UUID.Placeholder': 'ID отеля в TravelLine',
    'Parsing.Table.Field1': 'Название',
    'Parsing.Table.Field2': 'ID санатория',
    'Parsing.Table.Field3': 'Дата создания',
    'Parsing.Table.Field4': 'Интервал',
    'Parsing.Table.Field5': 'Количество тарифов',
    'Parsing.Table.Field6': 'Статус',
    'Parsing.Title': 'Парсинг тарифов TravelLine',
    'Parsing.Create': 'Новый запрос',

    'Parsing.Status.Created': 'Создан',
    'Parsing.Status.PendingHotel': 'Ожидание информации',
    'Parsing.Status.ParsingHotel': 'Получение  информации',
    'Parsing.Status.PendingRates': 'Ожидание тарифов',
    'Parsing.Status.ParsingRates': 'Получение тарифов',
    'Parsing.Status.Parsed': 'Парсинг окончен',
    'Parsing.Status.Completed': 'Запрос завершен',
    'Parsing.Status.Cancelled': 'Запрос отменен',
    'Parsing.Status.Expired': 'Запрос истек',
    'Parsing.Status.Failed': 'Ошибка парсинга',

    'Diseases.Title': 'Заболевания',
    'Diseases.Add': 'Добавить заболевание',
    'Disease.Form.Edit': 'Редактировать заболевание',
    'Disease.Subtitle': 'Название заболевания',
    'Disease.Form.Name': 'Название статьи',
    'Region.Form.Edit': 'Редактировать регион',
    'Object.Form.Edit': 'Редактировать объект',
    'Blog.Form.Edit': 'Редактировать блог',
    'Blog.Links.Action.Open': 'Скрыть все ссылки',
    'Blog.Links.Action.Close': 'Посмотреть все ссылки',
    'Blog.Title': 'Статьи',
    'Blog.Add': 'Добавить статью',
    'Objects.Title': 'Объекты',
    'Objects.Add': 'Добавить объект',
    'Regions.Title': 'Регионы',
    'Regions.Add': 'Добавить регион',
    'Regions.Coordinates.X': 'Широта',
    'Regions.Coordinates.X.Placeholder': 'Введите широту',
    'Regions.Coordinates.Y.Placeholder': 'Введите долготу',
    'Regions.Coordinates.Y': 'Долгота',
    'Regions.Form.Name': 'Название статьи',
    'Regions.Form.Subtitle': 'Название',
    'Regions.Coordinates': 'Координаты',
    'Regions.Subtitle': 'Название региона',
    'Hotels.Title': 'Объекты',
    'Hotels.Add': 'Добавить объект',
    'Common.Create': 'Создать',
    'Common.Yes': 'да',
    'Common.No': 'нет',
    'Common.Day': '{count, plural, one {# день} few {# дня} many {# дней}}',
    'Common.SearchArea': 'Область поиска',
    'Common.Replace': 'Заменить',
    'Common.Add': 'Добавить',
    'Common.Generate': 'Сгенерировать описание',
    'Common.UpdateGPT': 'Обновить перевод (en)',
    'Common.Form.Empty': 'Ничего не найдено',
    'Common.General': 'Общее',
    'Common.Name.Placeholder': 'Введите название',
    'Common.Seo.Title': 'Данные для SEO',
    'Common.Title': 'Заголовок',
    'Common.Title.Placeholder': 'Введите заголовок',
    'Common.Duration': 'Продолжительность {duration}',
    'Common.Description': 'Описание',
    'Common.Description.Placeholder': 'Введите описание',
    'Common.MetaTags': 'Мета тэги',
    'Common.MetaTags.Property': 'Тэг',
    'Common.MetaTags.PropertyPlaceholder': 'HTML тэг',
    'Common.MetaTags.Content': 'Значение',
    'Common.MetaTags.ContentPlaceholder': 'Введите значение',
    'Common.MetaTags.Name': 'Свойство',
    'Common.MetaTags.NamePlaceholder': 'Введите свойство',
    'Common.Topbar': 'Топбар',
    'Common.Template': 'Шаблон',
    'Common.Edit': 'Редактирование',
    'Common.Review': '{count, plural, one {# отзыв} few {# отзыва} many {# отзывов}}',
    'Common.Loading': 'Загрузка...',
    'HotelPage.Content.Programms': 'Программы',
    'HotelPage.Content.Specializations': 'Профили лечения',
    'HotelPage.Content.Procedures': 'Процедуры',
    'HotelPage.Content.MealOptions': 'Питание',
    'HotelPage.Content.Amenities': 'Удобства',
    'HotelPage.Content.Descriptions': 'Описание',
    'HotelCard.Action.ShowRooms': 'Показать номера',
    'HotelCard.Action.SelectRoom': 'Выбрать номер',
    'HotelCard.Unavailable': 'Тариф доступен на другие даты',
    'HotelPage.Content.Programms.Description':
      'Ознакомьтесь с программами лечения, в случае необходимости мы <span class="green">бесплатно</span> проконсультируем по любой программе.',
    'HotelPage.TreatmentPrograms.IncludedText': 'Что входит в программу'
  }
};
