import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IconXboxXFilled } from '@tabler/icons-react';
import { Box, CloseButton, Flex, Text } from '@mantine/core';

import AviaRailwayForm from 'components/ui/AviaRailwaySelectForm';
import { OSTROVOK_FIELDS } from '../schema';
import PrioritySelect from 'components/forms/PrioritySelect';
import { AirportAndRailwayField } from 'types/object';

type FormValues = {
  [OSTROVOK_FIELDS.STATIONS]: AirportAndRailwayField[];
};

interface RailwayProps {
  initialStations?: AirportAndRailwayField[];
}

function Railway({ initialStations }:RailwayProps) {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<FormValues>();
  const { fields, append, remove, update } = useFieldArray({
    name: OSTROVOK_FIELDS.STATIONS,
    control,
    keyName: 'id'
  });

  useEffect(() => {
    if (!initialStations) return;

    setValue(
      OSTROVOK_FIELDS.STATIONS,
      initialStations.length
        ? initialStations.map((station) => ({
            ...station,
            priority: station.priority?.toString() || '',
          }))
        : [{}]
    );
  }, [initialStations, setValue]);

  const handleSelect = (index: number, stationsData: any) => {
    const currentPriority = fields[index]?.priority;

    const updatedField = {
      code: stationsData.code,
      name: stationsData.name,
      city: stationsData.city,
      country: stationsData.country,
      coordinates: stationsData.coordinates,
      priority: currentPriority
    };

    update(index, updatedField);

    // @ts-ignore */
    setValue(`${SANATORY_FIELDS.STATIONS}.${index}`, updatedField, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };

  return (
    <Flex direction='column' mt='lg' gap='12px'>
      {fields.map((field, index) => (
        <Flex gap='16px' key={field.id} align='center'>
          <AviaRailwayForm
            width='100%'
            label={t('Objects.Railway.Form')}
            name={`${OSTROVOK_FIELDS.STATIONS}.${index}`}
            required
            onSelect={(data) => handleSelect(index, data)}
            value={field}
            type='railway'
          />
          <PrioritySelect
            label={t('Objects.Priority.Form')}
            placeholder={t('Objects.Priority.Form.Placeholder')}
            name={`${OSTROVOK_FIELDS.STATIONS}.${index}.${OSTROVOK_FIELDS.PRIORITY}`}
            defaultValue={field.priority}
            required
          />
          {index === 0 && <Box w={32} h={32} ml='19px' mt='20px' />}
          {index !== 0 && fields.length > 1 && (
            <CloseButton
              onClick={() => remove(index)}
              mt='25px'
              variant='subtle'
              radius='xl'
              size={32}
              icon={<IconXboxXFilled color='#C4C4C4' size={32} />}
            />
          )}
        </Flex>
      ))}
      <Text c='#1F6FCC' fz='14px' fw='bold' style={{ cursor: 'pointer' }} onClick={() => append({})}>
        {t('Objects.Railway.Add')}
      </Text>
    </Flex>
  );
}

export default Railway;
